import React from "react";
import './facilities.css';
import {boys, girls} from '../../data/facilities';

import GalleryDesign from "../Common/GalleryDesign";

const Hostel = () => {
    return(
        <div className="facilitiesBase">
            <h1 className="pageTitle">Hostel Facilities - BOYS</h1>
            <hr />
            <GalleryDesign data={boys} />
            <br />
            <h1 className="pageTitle">Hostel Facilities - GIRLS</h1>
            <hr />
            <GalleryDesign data={girls} />
        </div>
    )
}

export default Hostel;