import React from "react";

const Table = (props) => {
    const courses = props.courses;
    return(
        <>
        {
            courses.map(({key, name, seats, desc}) => {
                return(
                    <div className="table" key={key}>
                        <div className="block">
                            <p className="blockContent">{key}</p>
                        </div>
                        <div className="block">
                            <p className="blockContent">{name}</p>
                        </div>
                        <div className="block">
                            <p className="blockContent">{seats}</p>
                        </div>
                        <div className="block">
                            <p className="blockContent">{desc}</p>
                        </div>
                    </div>
                )
            })
        }
        </>
    )
}

export default Table;