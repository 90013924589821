const btechCourses = [
    {
      "key": 1,
      "name": "Mechanical Engg.",
      "seats": 30,
      "desc": "Physics, Chemistry & Mathematics"
    },
    {
        "key": 2,
        "name": "Civil Engg.",
        "seats": 30,
        "desc": "Physics, Chemistry & Mathematics"
    },
    {
        "key": 3,
        "name": "Computer Science & Engg.",
        "seats": 30,
        "desc": "Physics, Chemistry & Mathematics"
    },
    {
        "key": 4,
        "name": "Electrical & Electronics Engg.",
        "seats":30,
        "desc": "Physics, Chemistry & Mathematics"
    },
    {
        "key": 5,
        "name": "Artificial Intelligence & Data Science",
        "seats": 30,
        "desc": "Physics & Mathematics & 1 Subject from #"
    },
    {
        "key": 6,
        "name": "Agriculture Engg.",
        "seats": 30,
        "desc": "Physics, Chemistry or Agriculture Stream & 1 Subject from #"
    },
]

const btechLateralCourses = [
    {
      "key": 1,
      "name": "Mechanical Engg.",
      "seats": 30,
      "desc": "3 years diploma or B.Sc with 12th PCM"
    },
    {
        "key": 2,
        "name": "Civil Engg.",
        "seats": 30,
        "desc": "3 years diploma or B.Sc with 12th PCM"
    },
    {
        "key": 3,
        "name": "Computer Science & Engg.",
        "seats": 30,
        "desc": "3 years diploma or B.Sc with 12th PCM"
    },
    {
        "key": 4,
        "name": "Electrical & Electronics Engg.",
        "seats":30,
        "desc": "3 years diploma or B.Sc with 12th PCM"
    },
    {
        "key": 5,
        "name": "Artificial Intelligence & Data Science",
        "seats": 30,
        "desc": "3 years diploma or B.Sc with 12th PCM"
    },
    {
        "key": 6,
        "name": "Agriculture Engg.",
        "seats": 30,
        "desc": "3 years diploma or B.Sc with 12th PCM / PCB / Physics, Chemistry & Agriculture"
    },
]

const diploma = [
    {
      "key": 1,
      "name": "Mechanical Engg.",
      "seats": 30,
      "desc": "10th/ITI Passed"
    },
    {
        "key": 2,
        "name": "Civil Engg.",
        "seats": 30,
        "desc": "10th/ITI Passed"
    },
    {
        "key": 3,
        "name": "Computer Science & Engg.",
        "seats": 30,
        "desc": "10th/ITI Passed"
    },
    {
        "key": 4,
        "name": "Electrical & Electronics Engg.",
        "seats":30,
        "desc": "10th/ITI Passed"
    },
    {
        "key": 5,
        "name": "Electronics & Telecommunication Engg.",
        "seats":30,
        "desc": "10th/ITI Passed"
    },
    {
        "key": 6,
        "name": "Agriculture Engg.",
        "seats": 30,
        "desc": "10th/ITI Passed"
    },
]

const postGraduate = [
    {
        "key": 1,
        "name":"M. E.",
        "seats":"18",
        "desc": "Thermal System & Design"
    },
    {
        "key": 2,
        "name":"M.Tech",
        "seats":"18",
        "desc": "Thermal System & Design"
    }
]

export {btechCourses, btechLateralCourses, diploma, postGraduate};