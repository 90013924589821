import m1 from '../../Images/mentors/1.png';
import m2 from '../../Images/mentors/2.jpg';
import m3 from '../../Images/mentors/3.jpg';
import m4 from '../../Images/mentors/4.jpg';
import m5 from '../../Images/mentors/5.jpg';
import m6 from '../../Images/mentors/6.jpg';
import m7 from '../../Images/mentors/7.jpg';


const mentors = [
    {
        "id":1,
        "img": m1,
        "post": "President",
        "name": "Sardar Kuldeep Singh Bansal",
        "desc": "is leading businessman & is Sr. Vice- President Madhya Pradesh Weight Lifting Federation. He was President of Student Union of NES Law College & Vice President of Jabalpur University student union. He has been national player in weight lifting. He was Secretary of MP weight lifting Association, Vice- President MP Olympic association, Indian weight lifting association and Christ Church Alumini Association. He was President of Ramgaria Sikh Assciation, Vice-President Gurudwara Prem Nagar and Gold Medalist of disabled weight lifting in 2000."
    },
    {
        "id":2,
        "img": m2,
        "post": "Vice-President",
        "name": "Sardar Kartar Singh Bhatija",
        "desc": "is leading businessman of Jabalpur City & is President of Railway Contractor Association and so many other social Institutions. He is old Champion of Weight Lifting. For last 20 years he is associated with this Society."
    },
    {
        "id":3,
        "img": m3,
        "post": "Vice-President",
        "name": "Sardar Amarjeet Singh Khural",
        "desc": "is Government contractor in MES. He is Secretary of Rotary Club Jabalpur, member of Lodge Narmada 224, life member of Kadam Sanstha & Hamara Jabalpur, He is trustee of Justice Tankha memorial Rotary institute for Spastic and Handicap children of Jabalpur and Bilaspur. He is Vice-President of Jabalpur Punjabi Club, Life member of Jabalpur Chamber of Commerce and Industries, life member of Shri Guru Nanak Mission Education Society Prem Nagar, President of Jabalpur Sambhag of Madhya Pradesh Sikh Minority Welfare Committee. Patron member of Christ Church Alumni."
    },
    {
        "id":4,
        "img": m4,
        "post": "Secretary",
        "name": "Sardar Jitender Singh Saini",
        "desc": "is Government Contractor, Secretary of Gurudwara Adarsh Nagar, President of Jabalpur Punjabi Club, Cashier of MP Zamindara Association and Vice- Chairman of MES Builders Association."
    },
    {
        "id":5,
        "img": m5,
        "post": "Joint Secretary",
        "name": "Sardar Gurminder Singh Namdra",
        "desc": "is leading businessman of Jabalpur City and Social worker."
    },
    {
        "id":6,
        "img": m6,
        "post": "Treasurer",
        "name": "Sardar.Tejinder Singh Ghuldu",
        "desc": "is a retired Government employee. He is Joint Secretary of Retd. Pensioners Association WCR & a member of Lodge Narmada 224."
    },
    {
        "id":7,
        "img": m7,
        "post": "Manager",
        "name": "Sardar Gurdev Singh Reel (B. Sc.)",
        "desc": "Leading contractor of Mahakaushal region and is son of founder member & renowned social worker Sardar Sohan Singh Reel. He is also a renowned social worker. He is Vice-President Madhya Pradesh Jan Jagaran Morcha. He is vice-President of Madhya Pradesh Sikh Minority Welfare Committee."
    }
]

export default mentors;