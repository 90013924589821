import React from "react";

const Table = (props) => {
    const courses = props.data;
    return(
        <>
        {
            courses.map(({id, name, branch}) => {
                return(
                    <div className="table" key={id}>
                        <div className="block">
                            <p className="blockContent">{id}</p>
                        </div>
                        <div className="block">
                            <p className="blockContent">{name}</p>
                        </div>
                        <div className="block">
                            <p className="blockContent">{branch}</p>
                        </div>
                    </div>
                )
            })
        }
        </>
    )
}

export default Table;