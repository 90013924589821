import React from "react";
import './social.css'

import {AiFillFacebook} from 'react-icons/ai'
import {AiOutlineGooglePlus} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'



const SocialIconBar = () =>{
    return(
        <div className="iconBarSection">
            <div className="emptyDiv"></div>
            <div className="iconsDiv">
                <a href="https://grkist.edu.in/"><AiFillFacebook className="icon"/></a>
                <a href="https://grkist.edu.in/"><AiOutlineGooglePlus className="icon"/></a>
                <a href="https://grkist.edu.in/"><AiOutlineTwitter className="icon"/></a>
                <a href="https://grkist.edu.in/"><AiFillInstagram className="icon"/></a>
                <a href="https://grkist.edu.in/"><AiFillLinkedin className="icon"/></a>
            </div>
        </div>
    )
}

export default SocialIconBar;