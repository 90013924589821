import React from "react";
import './disclosure.css'
import disclosure from "./Mandatory-Disclosure.pdf";

import {FaHandPointDown} from 'react-icons/fa';
import {BsFillCloudArrowDownFill} from 'react-icons/bs';

const Disclosure = () => {
    return(
        <div className="disclosurePage">
            <h1 className="pageTitle">Mandatory Disclosure</h1>
            <hr />
            <p className="content">Download the mandatory disclosure byclicking on the button <FaHandPointDown className="icon"/></p>
            <button className="downloadBtn">
                <a target="_blank" href={disclosure} rel="noreferrer">
                    <BsFillCloudArrowDownFill className="icon"/>Download
                </a>
            </button>
        </div>
    )
}

export default Disclosure;