import React from "react";
import './tpo.css'

import Table from './TPOTable';
import {
    TCS_12_13, sukrut_12_13, real_12_13, davi_12_13, TCS_13_14, Knight_13_14, citrix_13_14, ascent_13_14, army_13_14, navigate_13_14,
    phyzer_13_14 , igt_13_14 , teleperformers_13_14 , karamtara_13_14 , TCS_14_15 , TCS_15_16 , capital_19 , jabong_19 , research_19 , TCS_19 
}
 from '../../../data/training&Placement'

const TPO = () => {
    return(
        <div className="tpoPage">
            <h1 className="pageTitle">TOP & Placements</h1>
            <hr />
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">Tata Consultancy Services(2019) : Pkg: 3.5LPA</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                    <Table data={TCS_19}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">Research Panel(2019) : Pkg: 3.0LPA</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={research_19}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">Jabong & Myntra Online (2019) (Pkg : 1.7-2.0 LPA)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={jabong_19}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">Capital Heights (2019) (Pkg : 2.2 LPA)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={capital_19}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">TCS - TATA CONSULTANCY SERVICES (2015-2016)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={TCS_15_16}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">TCS - TATA CONSULTANCY SERVICES (2014-2015)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={TCS_14_15}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">KARAMTARA INDUSTRIES (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={karamtara_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">TELEPERFORMERS (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={teleperformers_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">IGT (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={igt_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">PHYZER TECHNOLOGY (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={phyzer_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">NAVIGANT TECHNOLOGY (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={navigate_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">INDIAN ARMY - SSB (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={army_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">ASCENT NAVALS, THAILAND (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={ascent_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">CITRIX SYSTEMS, BANGALORE (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={citrix_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">KNIGHT VISION TECH, NOIDA (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={Knight_13_14}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">TCS - TATA CONSULTANCY SERVICES (2013-2014)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={TCS_13_14} />
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">DAVI ENGINEERING PVT. LTD. (2012-2013)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={davi_12_13}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">REAL POWER SOFTWARE (2012-2013)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={real_12_13}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">SUKRUT SYSTEM (2012-2013)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                <Table data={sukrut_12_13}/>
                </div>
            </div>
            <div className="eachPlacementDiv">
                <h2 className="companyTitle">TCS - TATA CONSULTANCY SERVICES (2012-2013)</h2>
                <div className="courseTableDiv">
                    <div className="table second">
                        <div className="block second">
                            <p className="blockContent">S. No.</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Name</p>
                        </div>
                        <div className="block second">
                            <p className="blockContent">Branch</p>
                        </div>
                    </div>
                    <Table data={TCS_12_13}/>
                </div>
            </div>
        </div>
    )
}

export default TPO;