import React from "react";
import './login.css'
import { Link } from "react-router-dom";

const Login = () => {
    return(
        <div className="loginPage">
            <div className="formSection">
                <h1 className="pageTitle">LOG<span>!N</span></h1>
                <form className="loginForm">
                    <label>Enrollment No</label>
                    <input type="text" placeholder="Enrollment no..." required/>

                    <label>Password</label>
                    <input type="password" placeholder="Enter password..." required/>

                    <button className="loginBtn">LOGIN</button>
                </form>
                <div className="extra">
                    <p className="text">Don't Have an Account?</p>
                    <p className="text link"><Link className="link" to={'/registration'}>Register Here</Link></p>
                </div>
            </div>
        </div>
    )
}

export default Login