import logo1 from '../../Images/logos/cisco.png';
import logo2 from '../../Images/logos/tcs.png';
import logo3 from '../../Images/logos/oracle.png';
import logo4 from '../../Images/logos/wipro.png';
import logo5 from '../../Images/logos/sap.jpg';
import logo6 from '../../Images/logos/ibm.png';


const accredition = [
    {
        "id":1,
        "img": logo1,
        "title": "CISCO",
        "desc": "Training on networking modules, equipments & network security is provides Certified Online examinations"
    },
    {
        "id":1,
        "img": logo2,
        "title": "TATA Consultancy services LTD",
        "desc": "TCS provides technical training, Industry Exposure"
    },    
    {
        "id":1,
        "img": logo3,
        "title": "ORACLE",
        "desc": "Under ORACLE University for the Work Force Development Programme (WDP)"
    },    
    {
        "id":1,
        "img": logo4,
        "title": "WIPRO",
        "desc": "Mission10X- Tie-up with Wipro under Mission 10X programme"
    },    
    {
        "id":1,
        "img": logo5,
        "title": "EDUCATION SAP",
        "desc": "Enterprise Resource Planning (ERP) world for SAP Modules"
    },    
    {
        "id":1,
        "img": logo6,
        "title": "IBM- Kvch",
        "desc": "is the best Training Institute for delivering quality of IT Software, Hardware & Networking Training to professionals & college aspirants."
    },
]

export default accredition;