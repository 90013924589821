import React from "react";
import './registration.css'
import { Link } from "react-router-dom";

const Registration = () => {
    return(
        <div className="registerPage">
            <div className="formSection">
                <h1 className="pageTitle">REG!STER<span>NOW</span></h1>
                <form className="loginForm">
                    <label>Enrollment No</label>
                    <input type="text" placeholder="Enrollment no..." required/>

                    <label>Name</label>
                    <input type="text" placeholder="Enter name..." required/>

                    <label>Password</label>
                    <input type="password" placeholder="Enter password..." required/>

                    <label>Confirm Password</label>
                    <input type="password" placeholder="Enter password..." required/>

                    <button className="loginBtn">REGISTER</button>
                </form>

                <div className="extra">
                    <p className="text">Already Have an Account?</p>
                    <p className="text link"><Link className="link" to={'/login'}>Login Here</Link></p>
                </div>
            </div>
        </div>
    )
}

export default Registration