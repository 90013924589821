import React from "react";
import './headers.css'

import header1 from '../../Images/image1.jpg'
import header2 from '../../Images/image4.jpg'
import header3 from '../../Images/image5.jpg'

const Headers = () =>{
    return(
        <div className="headerSectionDiv">
            <h1 className="section-main-title">Our Institute</h1>
            <div className="headerDiv">
                <div className="header-section flex">
                    <div className="imgDiv">
                        <img src={header1} alt="Our Institute"/>
                    </div>
                    <div className="textDiv">
                        <h2 className="header-title">OUR INSTITUTE</h2>
                        <p className="headerContent">Guru Ramdas Khalsa Institute of Science & Technology was established by Guru Gobind Singh Educational Society, Jabalpur started in July 1997. College started function from 3rd October 1997 after the approval of AICTE, New Delhi and Government of Madhya Pradesh</p>
                    </div>
                </div>
                <div className="header-section flex">
                    <div className="imgDiv">
                        <img src={header2} alt="Vision & Mission"/>
                    </div>
                    <div className="textDiv">
                        <h2 className="header-title">VISION & MISSION</h2>
                        <p className="headerContent">MOTO The motto of GGSES has been “Shubh Karman te kabahoon na tarron” meaning one should not heed from doing good work Vision Society understands that present era youth is committed to achieve success in their respective fields</p>
                    </div>
                </div>
                <div className="header-section flex">
                    <div className="imgDiv">
                        <img src={header3} alt="Admission in BTech"/>
                    </div>
                    <div className="textDiv">
                        <h2 className="header-title">ADMISSION IN B.TECH</h2>
                        <p className="headerContent">Admission Process B.Tech COURSES The Regular admission in Engineering takes place by appearing for Joint Entrance Exam JEE Main and MP Pre MCA test for seeking admission in MCA. Further appear through MP DTE on-line counselling. Students are admitted</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Headers;