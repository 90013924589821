import React from "react"
import './footer.css'

const Footer = () =>{
    return(
        <div className="footerDiv flex">
            <div className="left-container">
                <p className="footerContent">Copyright ©2023 GRKIST- All Rights Reserved.</p>
            </div>
            <div className="right-container">
                <p className="footerContent">Developed By Happy Adda Studios</p>
            </div>
        </div>
    )
}

export default Footer;