const TCS_12_13 = [
    {
        "id": 1,
        "name": "Anchal Dubey",
        "branch": "Electronics & Communication"
    },
    {
        "id": 2,
        "name": "Anukriti Saxena",
        "branch": "Electronics & Communication"
    },
    {
        "id": 3,
        "name": "Deepika Mishra",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 4,
        "name": "Divya Guru",
        "branch": "Electronics & Communication"
    },
    {
        "id": 5,
        "name": "Kuldeep Kachhwaha",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 6,
        "name": "KV Sanjana Sudhir",
        "branch": "Electronics & Communication"
    },
    {
        "id": 7,
        "name": "Manas Shrivastava",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 8,
        "name": "Meenal Sahni",
        "branch": "Electronics & Communication"
    },
    {
        "id": 9,
        "name": "Neha Sahni",
        "branch": "Electronics & Communication"
    },
    {
        "id": 10,
        "name": "Pratik Jaiswal",
        "branch": "Electronics & Communication"
    },
    {
        "id": 11,
        "name": "Satish Kushwaha",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 12,
        "name": "Sourabh Khare",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 13,
        "name": "Shatvisha Choudhury",
        "branch": "Electrical & Electronics"
    }
]

const sukrut_12_13 = [
    {
        "id": 1,
        "name": "Hemant Kushwaha",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 2,
        "name": "Tulika Dhar",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 3,
        "name": "Shivangi Gupta",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 4,
        "name": "Gaurav Mukherjee",
        "branch": "Electronics & Communication"
    },
    {
        "id": 5,
        "name": "Ritesh Dewan",
        "branch": "Electronics & Communication"
    },
]

const real_12_13 = [
    {
        "id": 1,
        "name": "Harsh Tiwari",
        "branch": "Electrical & Communication"
    },
    {
        "id": 2,
        "name": "Abha Shrivastava",
        "branch": "Electrical & Communication"
    },
    {
        "id": 3,
        "name": "Medha Tiwari",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 4,
        "name": "Pallavi Shende",
        "branch": "Electronics & Communication"
    },
    {
        "id": 5,
        "name": "Nivedita Tiwari",
        "branch": "Electronics & Communication"
    },
]

const davi_12_13 = [
    {
        "id": 1,
        "name": "Mithilesh Kumar Tiwari",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 2,
        "name": "Ahad Sikandar",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 3,
        "name": "Prashant Rai",
        "branch": "Mechanical Engineering"
    },
]

const TCS_13_14 = [
    {
        "id": 1,
        "name": "Sandhya",
        "branch": "Electronics & Communication"
    },
    {
        "id": 2,
        "name": "Nikita Saini",
        "branch": "Electronics & Communication"
    },
    {
        "id": 3,
        "name": "Kshipra Mishra",
        "branch": "Electrical & Communication"
    },
    {
        "id": 4,
        "name": "Abha Dubey",
        "branch": "Electronics & Communication"
    },
    {
        "id": 5,
        "name": "Akshat Tiwari",
        "branch": "Electronics & Communication"
    },
    {
        "id": 6,
        "name": "Gurpreet Matharoo",
        "branch": "Electronics & Communication"
    },
    {
        "id": 7,
        "name": "Sampada Kaurav",
        "branch": "Electronics & Communication"
    },
    {
        "id": 8,
        "name": "Zafar Imran",
        "branch": "Electronics & Communication"
    },
    {
        "id": 9,
        "name": "Kumar Tripathi",
        "branch": "Electronics & Communication"
    },
    {
        "id": 10,
        "name": "Alka Khare",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 11,
        "name": "Apurwa Gupta",
        "branch": "Computer Science & Engineering"
    },
    {
        "id": 12,
        "name": "Anshita Pathak",
        "branch": "Computer Science & Engineering"
    },
    {
        "id": 13,
        "name": "Rachita Khare",
        "branch": "Information Technology"
    }
]

const Knight_13_14 = [
    {
        "id": 1,
        "name": "Ashwariya Prajapati",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 2,
        "name": "Neelima Pachori",
        "branch": "Electronics & Communication"
    },
    {
        "id": 3,
        "name": "Shubanki Maurya",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 4,
        "name": "Mandakini Patel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 5,
        "name": "Neelendra Singh",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 6,
        "name": "Priyanka Tamarkar",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 7,
        "name": "Pushkar K Singh",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 8,
        "name": "Shipra Sing Bhagel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 9,
        "name": "Mukesh Kumar",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 10,
        "name": "Gayatri Pandey",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 11,
        "name": "Pawan Singh Bhadoria",
        "branch": "Electronics & Communication"
    },
    {
        "id": 12,
        "name": "Gaurav Singh",
        "branch": "Electronics & Communication"
    },
    {
        "id": 13,
        "name": "Kajal Tamarkar",
        "branch": "Electronics & Communication"
    },
    {
        "id": 14,
        "name": "Shiva Nema",
        "branch": "Electronics & Communication"
    },
    {
        "id": 15,
        "name": "Dibya Prakash Gorila",
        "branch": "Electronics & Communication"
    },
    {
        "id": 16,
        "name": "Anand Mohan",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 17,
        "name": "Surendra Singh Patel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 18,
        "name": "Neetesh Tiwari",
        "branch": "Electronics & Communication"
    },
    {
        "id": 19,
        "name": "Brajesh K Jha",
        "branch": "Electronics & Communication"
    },
    {
        "id": 20,
        "name": "Apoorva Banerjee",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 21,
        "name": "Priti Singh",
        "branch": "Electronics & Communication"
    },
    {
        "id": 22,
        "name": "Anil Kushwaha",
        "branch": "Electronics & Communication"
    },
    {
        "id": 23,
        "name": "Shalu Mishra",
        "branch": "Electronics & Communication"
    },
    {
        "id": 24,
        "name": "Shivangi Tiwari",
        "branch": "Electronics & Communication"
    },
    {
        "id": 25,
        "name": "Puneet Jain",
        "branch": "Electronics & Communication"
    },
    {
        "id": 26,
        "name": "Rashmi Diwakar",
        "branch": "Electronics & Communication"
    },
    {
        "id": 27,
        "name": "Richa Upadhyay",
        "branch": "Electronics & Communication"
    },
    {
        "id": 28,
        "name": "Preeti Jaiswar",
        "branch": "Electronics & Communication"
    }
]

const citrix_13_14 = [
    {
        "id": 1,
        "name": "Anand Mohani",
        "branch": "Comp.Science & Engineering"
    }
]

const ascent_13_14 = [
    {
        "id": 1,
        "name": "Pawan Singh",
        "branch": "Mechanical Engineering"
    }
]

const army_13_14 = [
    {
        "id": 1,
        "name": "Akshat Tiwari",
        "branch": "Comp.Science & Engineering"
    }
]

const navigate_13_14 = [
    {
        "id": 1,
        "name": "Poonam Thakur",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 2,
        "name": "Pushkar kumar Singh",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 3,
        "name": "Shalu Mishra",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 4,
        "name": "Ankita Raut",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 5,
        "name": "Richa Upadhyay",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 6,
        "name": "Vandana Sandiya",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 7,
        "name": "Rashmi Diwakar",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 8,
        "name": "Ayesha Begum",
        "branch": "Comp.Science & Engineering"
    }
]

const phyzer_13_14 = [
    {
        "id": 1,
        "name": "Anil Kushwaha",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 2,
        "name": "Surendra Singh Patel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 3,
        "name": "Ragini Chourasia",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 4,
        "name": "Vandana Sandiya",
        "branch": "Comp.Science & Engineering"
    },
]

const igt_13_14 = [
    {
        "id": 1,
        "name": "Priyanka Lakhanpal",
        "branch": "Comp.Science & Engineering"
    }
]

const teleperformers_13_14 = [
    {
        "id": 1,
        "name": "Puneet Jain",
        "branch": "Comp.Science & Engineering"
    }
]

const karamtara_13_14 = [
    {
        "id": 1,
        "name": "Swaraj Kumar",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 2,
        "name": "Radha Krishna Pandey",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 3,
        "name": "Ronit K Kori",
        "branch": "Comp.Science & Engineering"
    }
]

const TCS_14_15 = [
    {
        "id": 1,
        "name": "Md Ansari",
        "branch": "Electronics & Communication"
    },
    {
        "id": 2,
        "name": "Mohit Gupta",
        "branch": "Electronics & Communication"
    },
    {
        "id": 3,
        "name": "Anand Sharma",
        "branch": "Electronics & Communication"
    },
    {
        "id": 4,
        "name": "Vivek Agrawal",
        "branch": "Electronics & Communication"
    },
    {
        "id": 5,
        "name": "Paromica Sahoo",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 6,
        "name": "Vivek Tripathi",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 7,
        "name": "Rahul Thakur",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 8,
        "name": "Gulam Ansari",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 9,
        "name": "Sapna Patel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 10,
        "name": "Simranjeet Bajwa",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 11,
        "name": "Nishant Thomas",
        "branch": "Electronics & Communication"
    },
    {
        "id": 12,
        "name": "Nilansh Srijan",
        "branch": "Electronics & Communication"
    },
    {
        "id": 13,
        "name": "Seema Goswami",
        "branch": "Electronics & Communication"
    },
    {
        "id": 14,
        "name": "Pallavi Pillay",
        "branch": "Electronics & Communication"
    },
    {
        "id": 15,
        "name": "Vasundhara Giri",
        "branch": "Electronics & Communication"
    },
    {
        "id": 16,
        "name": "Ashwarya Mishra",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 17,
        "name": "Piyush Pathik",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 18,
        "name": "Neetu Jain",
        "branch": "Electronics & Communication"
    },
    {
        "id": 19,
        "name": "Shalu Singh",
        "branch": "Electronics & Communication"
    },
]

const TCS_15_16 = [
    {
        "id": 1,
        "name": "Krishna Tripathi",
        "branch": "Electronics & Communication"
    },
    {
        "id": 2,
        "name": "Sanjay Yadav",
        "branch": "Electronics & Communication"
    },
    {
        "id": 3,
        "name": "Anjali Sapre",
        "branch": "Electronics & Communication"
    },
    {
        "id": 4,
        "name": "Jaya Singh",
        "branch": "Electronics & Communication"
    },
    {
        "id": 5,
        "name": "Priya Dubey",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 6,
        "name": "Anmol Namdeo",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 7,
        "name": "Vibha Dubey",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 8,
        "name": "Deksha Rai",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 9,
        "name": "Renuka Khirwadkar",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 10,
        "name": "Chitrarupa Sharma",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 11,
        "name": "Neha Lashkare",
        "branch": "Electronics & Communication"
    },
    {
        "id": 12,
        "name": "Kuldeep Kumar",
        "branch": "Electronics & Communication"
    },
    {
        "id": 13,
        "name": "Adtiya Sharma",
        "branch": "Electronics & Communication"
    },
]

const capital_19 = [
    {
        "id": 1,
        "name": "Monika Sahu",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 2,
        "name": "Nandlal Sah",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 3,
        "name": "Priyanka Baghel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 4,
        "name": "Doly Upadhyay",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 5,
        "name": "Pooja Bain",
        "branch": "Electronics & Communication"
    },
    {
        "id": 6,
        "name": "Neelesh Patel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 7,
        "name": "Komal Singh",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 8,
        "name": "Abhishek Kumar Jha",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 9,
        "name": "Vishal Kumar",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 10,
        "name": "Sejal Pandey",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 11,
        "name": "Abhijit Kumar",
        "branch": "Civil Engineering"
    },
    {
        "id": 12,
        "name": "Chandan Sah",
        "branch": "Civil Engineering"
    },
    {
        "id": 13,
        "name": "Susheel Kumar Singh",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 14,
        "name": "Utkarsh Kumar  Sharma",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 15,
        "name": "MD. Muzammil",
        "branch": "Mechanical Engineering"
    },
    {
        "id": 16,
        "name": "Kundan Sah",
        "branch": "Civil Engineering"
    },
    {
        "id": 17,
        "name": "Priya Pandey",
        "branch": "Electronics & Communication"
    },
]

const jabong_19 = [
    {
        "id": 1,
        "name": "Komal Singh",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 2,
        "name": "Sejal Pandey",
        "branch": "Comp.Science & Engineering"
    }
]

const research_19 = [
    {
        "id": 1,
        "name": "Mayur Sahu",
        "branch": "Civil Engineering"
    },
    {
        "id": 2,
        "name": "Nidhi Suryawanshi",
        "branch": "Civil Engineering"
    },
    {
        "id": 3,
        "name": "Priyanka Baghel",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 4,
        "name": "Surbhi Sahu",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 5,
        "name": "Pooja Bain",
        "branch": "Electronics & Communication"
    },
    {
        "id": 6,
        "name": "Komal Singh",
        "branch": "Electrical & Electronics"
    },
    {
        "id": 7,
        "name": "Sejal Pandey",
        "branch": "Comp.Science & Engineering"
    },
    {
        "id": 8,
        "name": "Priya Pandey",
        "branch": "Electronics & Communication"
    },
    {
        "id": 9,
        "name": "Utkarsh Sharma",
        "branch": "Comp.Science & Engineering"
    }
]

const TCS_19 = [
    {
        "id": 1,
        "name": "Rahul Kumar",
        "branch": "Comp.Science & Engineering"
    }
]

export {
    TCS_12_13, sukrut_12_13, real_12_13, davi_12_13, TCS_13_14, Knight_13_14, citrix_13_14, ascent_13_14, army_13_14, navigate_13_14,
    phyzer_13_14 , igt_13_14 , teleperformers_13_14 , karamtara_13_14 , TCS_14_15 , TCS_15_16 , capital_19 , jabong_19 , research_19 , TCS_19 
};