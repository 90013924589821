import React from "react"
import './main.css'

const Main = () =>{
    return(
        <div className="mainDiv">
            <div className="section-title">
                <span>WELCOME TO</span><br />
                <h1 className="title">GURU RAMDAS KHALSA INSTITUTE OF SCIENCE & TECHNOLOGY</h1>
            </div>
            <div className="textContent">
                <p className="textPara">
                Guru Ramdas Khalsa Institute of Science & Technology is promoted by a well known Society, Guru Gobind Singh Educational Society, Jabalpur which is self financed society approved by AICTE, New Delhi, and is affiliated to Rajiv Gandhi Proudyogiki Vishwavidhyalaya, Bhopal (M.P).
                </p>
                <p className="textPara">
                GRKIST, Jabalpur started BE courses in July 1997 and has come up with an excellent infrastructure and facilities required to provide quality education to students from all strata of society. This academy is at the foothills of Jabalpur abounds in greenery and offers an idyllic tranquil surrounding needed for a comprehensive development of mind and body.
                </p>
                <p className="textPara">
                The institution provides individualized attention to the students thus enhancing their ability to acquire & apply knowledge. Vibrant and skilled shrilled faculty base inducted both from academia and industry with impressive credentials is working with a necessary zeal for teaching and shaping careers.
                </p>
            </div>
        </div>
    )
}

export default Main;