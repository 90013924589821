import React from "react";
import './courses.css'

import {postGraduate} from '../../data/courses'
import Table from "./Table";

const PostGraduate = () => {
    return(
        <div className="coursesBase" style={{"marginBottom": "6rem"}}>
            <h1 className="sectionTitle">Post Graduate Courses</h1>
            <hr />
            <div className="courseContentDiv">
                <h2 className="courseTitle">Masters Courses</h2>
                <p>2 years Program</p>
                <p><span>Eligibility:</span>Eligibility - B. Tech in Mechanical / Automobile / RAC</p>
            </div>
            <div className="courseTableDiv">
                <div className="table second">
                    <div className="block second">
                        <p className="blockContent">S. No.</p>
                    </div>
                    <div className="block second">
                        <p className="blockContent">Course</p>
                    </div>
                    <div className="block second">
                        <p className="blockContent">Seats</p>
                    </div>
                    <div className="block second">
                        <p className="blockContent">Branch</p>
                    </div>
                </div>
                <Table courses={postGraduate}/>
            </div>

            <br />
            <br />
        </div>
    );
}

export default PostGraduate;