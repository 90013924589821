import React from "react";
import './accredition.css'
import accredition from "../../../data/accredition";

const Accredition = () => {
    console.log("add", accredition);
    return(
        <div className="accreditionPage">
            <h1 className="pageTitle">
                Accreditation & Tie Ups
            </h1>
            <div className="tabsGridDiv">
                    {
                        accredition.map(({id, img, title, desc}) => {
                            return(
                                <div key={id} className="eachTab">
                                    <div className="imgDiv">
                                        <img className="tabImg" src={img} alt={title}/>
                                    </div>
                                    <div className="textArea">
                                        <h3 className="text">
                                            <span>{title} - </span>
                                            {desc}
                                        </h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
        </div>
    )
}

export default Accredition;