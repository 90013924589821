import React from "react";
import './faq.css'

const FAQ = () => {
    return(
        <div className="faqPage">
            <h1 className="pageTitle">Career at GRKIST</h1>
            <hr />
            <p className="text">
            <span>Guru Ramdas Khalsa Institute of Science & Technology,</span> near Village :- Kukrikheda Jabalpur provides right ambiance and ample opportunities for job satisfaction and career advancement.
            </p>
            <p className="text">
            Qualified and experienced professionals from various disciplines of science, engineering and technology with proven track record of achievements and having potential to promote excellence in their areas of expertise are always welcome to be the part of our winning team.
            </p>
            <p className="text">
            Please send your resume to: <a className="mailLink" href="https://grkist.edu.in/contactus.php">office@grkist.com</a>
            </p>
        </div>
    )
}

export default FAQ;