import React, {useState} from "react";
import './grievance.css'

const GrievanceSection = () => {
    const [data, setData] = useState({
        name: "",
        email: "",
        description: ""
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({...data, [name]: value})
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, description} = data;
        const res = fetch("",
        {
            method : "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify({ name, email, description})
        });

        if(res){
            setData({
                name: "",
                email: "",
                description: ""
            });
            alert("Data Stored")
        } else {
            alert("Message didn't sent");
        }
    }

    return(
        <div className="grievanceSectionPage">
            <h1 className="pageTitle">Student Grievance Redressal Cell</h1>
            <hr />
            <div className="contentSection">
                <div className="leftSection">
                    <h2 className="sectionSubTitle">Contact for Student's Grievance Redressal Cell</h2>
                    <div className="contentTable">
                        <div className="tableDivSection">
                            <div className="section">
                                <p className="sectionContent">Mr Raj Mehta :</p>
                            </div>
                            <div className="section">
                                <p className="sectionContent"><span>Contact: </span>7772863304</p>
                                <p className="sectionContent"><span>Email: </span>raj.2512@rediffmail.com</p>
                            </div>
                        </div>
                        <div className="tableDivSection">
                            <div className="section">
                                <p className="sectionContent">Dr. Ramneek Kaur :</p>
                            </div>
                            <div className="section">
                                <p className="sectionContent"><span>Contact: </span>9981011599</p>
                                <p className="sectionContent"><span>Email: </span>office@grkist.com</p>
                            </div>
                        </div>
                        <div className="tableDivSection">
                            <div className="section">
                                <p className="sectionContent">Mrs. Rekha Sakhuja :</p>
                            </div>
                            <div className="section">
                                <p className="sectionContent"><span>Contact: </span>9425151830</p>
                                <p className="sectionContent"><span>Email: </span>rekhaskhuja@yahoo.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightSection">
                    <h2 className="sectionSubTitle">Grievance Form</h2>
                    <hr />
                    <form className="contactForm">

                        <p className="label">Enrollment No.</p>
                        <input type="text" placeholder="Enter enrollment no..." name="enroll" onChange={handleChange} value = {data.enroll}/>

                        <p className="label">Name</p>
                        <input type="text" placeholder="Enter name..." name="name" onChange={handleChange} value = {data.name}/>

                        <p className="label">Course</p>
                        <input type="text" placeholder="Enter course..." name="course" onChange={handleChange} value = {data.course}/>

                        <p className="label">Branch</p>
                        <input type="text" placeholder="Enter branch..." name="branch" onChange={handleChange} value = {data.branch}/>

                        <p className="label">Grievance</p>
                        <textarea cols={30} rows={5} placeholder="type here..." name="girevance" onChange={handleChange} value = {data.grievance}/>
                        
                        <button className="formBtn" onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default GrievanceSection;