import React from "react";
import { Link } from "react-router-dom";
import './mainFooter.css'

import footerImg from '../../Images/footer.jpg'
// import logo from '../../Images/logo.png'

import {BsTelephone} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'
import {BsGlobeAmericas} from 'react-icons/bs'
import {AiFillFacebook} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'

const MainFooter = () => {
    return(
        <div className="mainFooterDiv">
            <div className="imgDiv">
                <img className="footerImg" src={footerImg} alt="GRKIST"/>
            </div>
            <div className="footerContentDiv">
                <div className="leftSection">
                    {/* <div className="logoDiv">
                        <img className="logoImg" src={logo} alt="GRKIST" />
                    </div> */}
                    <div className="leftContentDiv">
                        <h2 className="title">Guru Ramdas Khalsa Institute of Science & Technology</h2>
                        <h2 className="address">Kukri Kheda, Barela, Madhya Pradesh 483001</h2>
                        <p className="contact"><BsTelephone className="icon"/>0761-2902864</p>
                        <p className="contact"><AiOutlineMail className="icon"/>office@grkist.com</p>
                        <p className="contact"><BsGlobeAmericas className="icon"/>www.grkist.edu.in</p>
                        <div className="socialIcons">
                            <div className="iconDiv">
                                <AiFillFacebook className="icon"/>
                            </div>
                            <div className="iconDiv">
                                <AiOutlineTwitter className="icon"/>
                            </div>
                            <div className="iconDiv">
                                <AiFillLinkedin className="icon"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightSection">
                    <div className="topSection">
                        <div className="block">
                            <h1 className="blockTitle">Quick Links</h1>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/courses/undergraduate-courses'}>Undergraduate Courses</Link></p>
                            </div>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/placement/tpo&placements'}>Placements</Link></p>
                            </div>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/contactus/connect'}>Contact Us</Link></p>
                            </div>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/faq'}>FAQs</Link></p>
                            </div>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/about-us/mandatory-disclosure'}>Mandatory Disclosure</Link></p>
                            </div>
                        </div>
                        <div className="block">
                            <h1 className="blockTitle">Explore</h1>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/gallery'}>Campus</Link></p>
                            </div>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/facilities/hostel'}>Facilities</Link></p>
                            </div>
                            <div className="linkTextDiv">
                                <p className="linkText"><Link className="link color" to={'/about-us/mentors'}>Mentors</Link></p>
                            </div>
                        </div>
                    </div>
                    <div className="mapSection">
                        <h1 className="blockTitle">Address</h1>
                        <div className="linkTextDiv">
                            <p className="linkText"><Link className="link"></Link></p>
                        </div>
                        <div className="mapArea">
                            <iframe 
                                className="map" 
                                title="GRKIST" 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.8350739105663!2d80.05065877515881!3d23.066507314687428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981ab2acb59131b%3A0x6d969d8625dbf2e5!2sGuru%20Ramdas%20Khalsa%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1687251088075!5m2!1sen!2sin" 
                                // width="600" 
                                // height="450"  
                                allowFullScreen="true" 
                                loading="lazy" 
                                referrerpolicy="no-referrer-when-downgrade"
                                >

                            </iframe>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MainFooter;