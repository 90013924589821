import React from "react";

import { sports } from "../../data/facilities";
import GalleryDesign from "../Common/GalleryDesign";

const SportAndCultural = () => {
    return(
        <div className="facilitiesBase">
            <h1 className="pageTitle">Sports & Cultural</h1>
            <hr />
            <GalleryDesign data={sports} />
        </div>
    )
}

export default SportAndCultural;