import boys1 from '../../Images/Hostel/boys1.jpg'
import boys2 from '../../Images/Hostel/boys2.jpg'
import boys3 from '../../Images/Hostel/boys3.jpg'

import girls1 from '../../Images/Hostel/girls1.jpg'
import girls2 from '../../Images/Hostel/girls2.jpg'
import girls3 from '../../Images/Hostel/girls3.jpg'

import ncc1 from '../../Images/NCC/1.jpg'
import ncc2 from '../../Images/NCC/2.jpg'
import ncc3 from '../../Images/NCC/3.jpg'
import ncc4 from '../../Images/NCC/4.jpg'
import ncc5 from '../../Images/NCC/5.jpg'
import ncc6 from '../../Images/NCC/6.jpg'
import ncc7 from '../../Images/NCC/7.jpg'
import ncc8 from '../../Images/NCC/8.jpg'
import ncc9 from '../../Images/NCC/9.jpg'

import l1 from '../../Images/Labs/1.jpg'
import l2 from '../../Images/Labs/2.jpg'
import l3 from '../../Images/Labs/3.jpg'
import l4 from '../../Images/Labs/4.jpg'
import l5 from '../../Images/Labs/5.jpg'
import l6 from '../../Images/Labs/6.jpg'
import l7 from '../../Images/Labs/7.jpg'
import l8 from '../../Images/Labs/8.jpg'
import l9 from '../../Images/Labs/9.jpg'
import l10 from '../../Images/Labs/10.jpg'
import l11 from '../../Images/Labs/11.jpg'
import l12 from '../../Images/Labs/12.jpg'
import l13 from '../../Images/Labs/13.jpg'
import l14 from '../../Images/Labs/14.jpg'
import l15 from '../../Images/Labs/15.jpg'
import l16 from '../../Images/Labs/16.jpg'
import l17 from '../../Images/Labs/17.jpg'
import l18 from '../../Images/Labs/18.jpg'
import l19 from '../../Images/Labs/19.jpg'
import l20 from '../../Images/Labs/20.jpg'
import l21 from '../../Images/Labs/21.jpg'
import l22 from '../../Images/Labs/22.jpg'
import l23 from '../../Images/Labs/23.jpg'
import l24 from '../../Images/Labs/24.jpg'
import l25 from '../../Images/Labs/25.jpg'
import l26 from '../../Images/Labs/26.jpg'





const boys = [
    {
        "id":1,
        "src": boys1
    },
    {
        "id":2,
        "src": boys2
    },
    {
        "id":3,
        "src": boys3
    },
]

const girls = [
    {
        "id":1,
        "src": girls1
    },
    {
        "id":2,
        "src": girls2
    },
    {
        "id":3,
        "src": girls3
    },
]

const sports = [
    {
        "id":1,
        "src": ncc1
    },
    {
        "id":2,
        "src": ncc2
    },
    {
        "id":3,
        "src": ncc3
    },
    {
        "id":4,
        "src": ncc4
    },
    {
        "id":5,
        "src": ncc5
    },
    {
        "id":6,
        "src": ncc6
    },
    {
        "id":7,
        "src": ncc7
    },

        {
        "id":8,
        "src": ncc8
    },
    {
        "id":9,
        "src": ncc9
    },
]

const labs = [
    {
        "id":1,
        "src": l1
    },
    {
        "id":2,
        "src": l2
    },
    {
        "id":3,
        "src": l3
    },
    {
        "id":4,
        "src": l4
    },
    {
        "id":5,
        "src": l5
    },
    {
        "id":6,
        "src": l6
    },
    {
        "id":7,
        "src": l7
    },

        {
        "id":8,
        "src": l8
    },
    {
        "id":9,
        "src": l9
    },
    {
        "id":10,
        "src": l10
    },
    {
        "id":11,
        "src": l11
    },
    {
        "id":12,
        "src": l12
    },
    {
        "id":13,
        "src": l13
    },
    {
        "id":14,
        "src": l14
    },
    {
        "id":15,
        "src": l15
    },
    {
        "id":16,
        "src": l16
    },

        {
        "id":17,
        "src": l17
    },
    {
        "id":18,
        "src": l18
    },
    {
        "id":19,
        "src": l19
    },
    {
        "id":20,
        "src": l20
    },
    {
        "id":21,
        "src": l21
    },
    {
        "id":22,
        "src": l22
    },
    {
        "id":23,
        "src": l23
    },
    {
        "id":24,
        "src": l24
    },
    {
        "id":25,
        "src": l25
    },

        {
        "id":26,
        "src": l26
    }
]

export {boys, girls, sports, labs};