import React from "react";
import './courses.css'

import {btechCourses, btechLateralCourses, diploma} from '../../data/courses'
import Table from "./Table";

const Courses = () => {
    return(
        <div className="coursesBase">
            <h1 className="sectionTitle">Under Graduate Courses</h1>
            <hr />
            <div className="courseContentDiv">
                <h2 className="courseTitle">Bachelor of Technology</h2>
                <p>4 years Program</p>
                <p><span>Eligibility:</span> 12th Passed / JEE</p>
            </div>
            <div className="courseTableDiv">
                <div className="table second">
                    <div className="block second">
                        <p className="blockContent">S. No.</p>
                    </div>
                    <div className="block second">
                        <p className="blockContent">Course</p>
                    </div>
                    <div className="block second">
                        <p className="blockContent">Seats</p>
                    </div>
                    <div className="block second">
                        <p className="blockContent">Mandatory Subjects</p>
                    </div>
                </div>
                <Table courses={btechCourses}/>
            </div>
            <div className="subjectList">
                <p>
                <span>#</span> Physics / Mathematics / Chemistry / Computer Science / Electronics / Information Technology / Biology Informatics Practices / Biotechnology / Technical Vocational Subject / Agriculture / Engineering Graphics / Business Studies / Entrepreneurship 
                </p>
            </div>

            <br />
            <br />

            <div className="courseContentDiv">
                <h2 className="courseTitle">BTech Lateral Entry Courses</h2>
                <p>3 Years Courses</p>
                <p>Direct Admission in BTech 2nd Year Course</p>
            </div>

            <div className="courseTableDiv">

                <div className="table second">
                    <div className="block">
                        <p className="blockContent">S. No.</p>
                    </div>
                    <div className="block">
                        <p className="blockContent">Course</p>
                    </div>
                    <div className="block">
                        <p className="blockContent">Seats</p>
                    </div>
                    <div className="block">
                        <p className="blockContent">Eligibility</p>
                    </div>
                </div>
                <Table courses={btechLateralCourses}/>
            </div>

            <br />
            <br />
            
            <div className="courseContentDiv">
                <h2 className="courseTitle">Polytechnic Diploma</h2>
                <p>3 Years Course</p>
            </div>
            <div className="courseTableDiv">

                <div className="table second">
                    <div className="block">
                        <p className="blockContent">S. No.</p>
                    </div>
                    <div className="block">
                        <p className="blockContent">Course</p>
                    </div>
                    <div className="block">
                        <p className="blockContent">Seats</p>
                    </div>
                    <div className="block">
                        <p className="blockContent">Eligibility</p>
                    </div>
                </div>
                <Table courses={diploma}/>
            </div>
        </div>
    );
}

export default Courses;