import React from "react";
import './galleryDesign.css'

const GalleryDesign = (props) => {
    const boys = props.data
    console.log(props.data)

    return(
        <div className="galleryLook">
            <div className="imgGridDiv">
                {
                    boys.map((boy) => {
                        return(
                            <div className="imgDiv">
                                <img className="image" key={boy.id} src={boy.src} alt="ImageTags"/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default GalleryDesign;