import a1 from '../../Images/ALL/1.jpg'
import a2 from '../../Images/ALL/2.jpg'
import a3 from '../../Images/ALL/3.jpg'
import a4 from '../../Images/ALL/4.jpg'
import a5 from '../../Images/ALL/5.jpg'
import a6 from '../../Images/ALL/6.jpg'
import a7 from '../../Images/ALL/7.jpg'
import a8 from '../../Images/ALL/8.jpg'
import a9 from '../../Images/ALL/9.jpg'
import a10 from '../../Images/ALL/10.jpg'
import a11 from '../../Images/ALL/11.jpg'
import a12 from '../../Images/ALL/12.jpg'


import aa1 from '../../Images/Activities/1.jpg'
import aa2 from '../../Images/Activities/2.jpg'
import aa3 from '../../Images/Activities/3.jpg'
import aa4 from '../../Images/Activities/4.jpg'
import aa5 from '../../Images/Activities/5.jpg'
import aa6 from '../../Images/Activities/6.jpg'
import aa7 from '../../Images/Activities/7.jpg'

import academics1 from '../../Images/Academics/1.jpg'
import academics2 from '../../Images/Academics/2.jpg'
import academics3 from '../../Images/Academics/3.jpg'
import academics4 from '../../Images/Academics/4.jpg'
import academics5 from '../../Images/Academics/5.jpg'
import academics6 from '../../Images/Academics/6.jpg'
import academics7 from '../../Images/Academics/7.jpg'
import academics8 from '../../Images/Academics/8.jpg'
import academics9 from '../../Images/Academics/9.jpg'
import academics10 from '../../Images/Academics/10.jpg'
import academics11 from '../../Images/Academics/11.jpg'
import academics12 from '../../Images/Academics/12.jpg'
import academics13 from '../../Images/Academics/13.jpg'
import academics14 from '../../Images/Academics/14.jpg'
import academics15 from '../../Images/Academics/15.jpg'
import academics16 from '../../Images/Academics/16.jpg'
import academics17 from '../../Images/Academics/17.jpg'
import academics18 from '../../Images/Academics/18.jpg'
import academics19 from '../../Images/Academics/19.jpg'
import academics20 from '../../Images/Academics/20.jpg'
import academics21 from '../../Images/Academics/21.jpg'
import academics22 from '../../Images/Academics/22.jpg'
import academics23 from '../../Images/Academics/23.jpg'
import academics24 from '../../Images/Academics/24.jpg'
import academics25 from '../../Images/Academics/25.jpg'
import academics26 from '../../Images/Academics/26.jpg'
import academics27 from '../../Images/Academics/27.jpg'



const all = [
    {
        "id": 1,
        "src": a1
    },
    {
        "id": 2,
        "src": a2
    },
    {
        "id": 3,
        "src": a3
    },    
    {
        "id": 4,
        "src": a4
    },    
    {
        "id": 5,
        "src": a5
    },    
    {
        "id": 6,
        "src": a6
    },    
    {
        "id": 7,
        "src": a7
    },    
    {
        "id": 8,
        "src": a8
    },    
    {
        "id": 9,
        "src": a9
    },    
    {
        "id": 10,
        "src": a10
    },    
    {
        "id": 11,
        "src": a11
    },    
    {
        "id": 12,
        "src": a12
    },
]

const activities = [
    {
        "id": 1,
        "src": aa1
    },
    {
        "id": 2,
        "src": aa2
    },
    {
        "id": 3,
        "src": aa3
    },    
    {
        "id": 4,
        "src": aa4
    },    
    {
        "id": 5,
        "src": aa5
    },    
    {
        "id": 6,
        "src": aa6
    },    
    {
        "id": 7,
        "src": aa7
    }, 
]

const academics = [
    {
        "id": 1,
        "src": academics1
    },
    {
        "id": 2,
        "src": academics2
    },
    {
        "id": 3,
        "src": academics3
    },    
    {
        "id": 4,
        "src": academics4
    },    
    {
        "id": 5,
        "src": academics5
    },    
    {
        "id": 6,
        "src": academics6
    },    
    {
        "id": 7,
        "src": academics7
    },    
    {
        "id": 8,
        "src": academics8
    },    
    {
        "id": 9,
        "src": academics9
    },    
    {
        "id": 10,
        "src": academics10
    },    
    {
        "id": 11,
        "src": academics11
    },    
    {
        "id": 12,
        "src": academics12
    },    
    {
        "id": 13,
        "src": academics13
    },   
    {
        "id": 14,
        "src": academics14
    },   
    {
        "id": 15,
        "src": academics15
    },    
    {
        "id": 16,
        "src": academics16
    },    
    {
        "id": 17,
        "src": academics17
    },  
    {
        "id": 18,
        "src": academics18
    },   
    {
        "id": 19,
        "src": academics19
    },    
    {
        "id": 20,
        "src": academics20
    },    
    {
        "id": 21,
        "src": academics21
    },    
    {
        "id": 22,
        "src": academics22
    },    
    {
        "id": 23,
        "src": academics23
    },    
    {
        "id": 24,
        "src": academics24
    },    
    {
        "id": 25,
        "src": academics25
    },    
    {
        "id": 26,
        "src": academics26
    },    
    {
        "id": 27,
        "src": academics27
    },
]

export {all, activities, academics};