import React from "react";
import './vtForm.css'
import disclosure from "../../../../pdf/vt_form.pdf";

import {FaHandPointDown} from 'react-icons/fa';
import {BsFillCloudArrowDownFill} from 'react-icons/bs';

const VTForm = () => {
    return(
        <div className="disclosurePage">
            <h1 className="pageTitle">VT_Form</h1>
            <hr />
            <p className="content">Download the VT_Form byclicking on the button <FaHandPointDown className="icon"/></p>
            <button className="downloadBtn">
                <a target="_blank" href={disclosure} rel="noreferrer">
                    <BsFillCloudArrowDownFill className="icon"/>Download
                </a>
            </button>
        </div>
    )
}

export default VTForm;