import React from "react";
import './principle.css'

import img from '../../Images/mentors/principal.jpeg';

const PrincipleHomeSection = () => {
    return(
        <div className="principlePage">
            <h1 className="pageTitle">From The Principal</h1>
            <hr />
            <div className="messageDiv">
                <div className="imgDiv">
                    <img className="img" src={img} alt="Principal's Message"/>
                </div>
                <div className="message">
                    <p className="text">
                    Welcome to GURU RAMDAS KHALSA INSTITUTE OF TECHNOLOGY (GRKIST)
                    </p>
                    <p className="text">
                    The basic purpose of the Institute is to transform young, enthusiastic students into professionally competent engineers capable of accepting the challenges of industries. Institute provide sample opportunities for interaction with the experts from the industry through lectures, field visits, various trainings, activities of the student chapters of professional bodies etc.
                    </p>
                    <p className="text">
                    Employability and professional skills formulate a critical part of engineering education. These skills are imbibed through a special program to students which gives them training in core competencies and gives them finishing touch of values and etiquettes.
                    </p>
                    <p className="text">
                    Quality is the main objective. It is not an accident but it's a habit. I recollect Mr. Henry Ford's remarks: “Quality means doing it right when no one is looking.”
                    </p>
                    <p className="text">
                    I am sure that you will be able to achieve your goals.
                    </p>
                    <p className="text">
                    We invite you to be a part of this successful tradition.
                    </p>
                    <p className="text">
                    Best Wishes!!!!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrincipleHomeSection;