import React from "react";
import './details.css'

const Details = () => {

    return(
        <div className="contactDetailsPage">
            <h1 className="pageTitle">Contact Us</h1>
            <div className="detailsDiv">
                <div className="leftSection">
                    <h2 className="subHeading">Guru Ramdas Khalsa Institute of Technology</h2>
                    <hr />
                    <div className="content">
                        <p className="header">Address:</p>
                        <p className="part">Kukri Kheda, Barela, Jabalpur, Madhya Pradesh - 483001</p>
                    </div>
                    <div className="content">
                        <p className="header">For Admission :</p>
                        <p className="part">8226006346, 9425387850, 8889233301, 9425800467</p>
                    </div>
                    <div className="content">
                        <p className="header">Office Phone :</p>
                        <p className="part">0761-2902864, 2902866</p>
                    </div>
                    <div className="content">
                        <p className="header">Registrar Office :</p>
                        <p className="part">+918226006222</p>
                    </div>
                    <div className="content">
                        <p className="header">Principal Office :</p>
                        <p className="part">+918226006223 (For Admission & any Enquiery)</p>
                    </div>
                    <div className="content">
                        <p className="header">Website :</p>
                        <p className="part">www.grkist.edu.in</p>
                    </div>
                    <div className="content">
                        <p className="header">College Working Days and Timing :</p>
                        <div className="part">
                            <p className="partText">Monday -to- Friday - [9:00AM -to- 4:00PM]</p>
                            <p className="partText">Saturday & Sunday - Closed.</p>
                        </div>
                    </div>
                    <h3 className="subHeadingList">Emails:</h3>
                    <hr />
                    <div className="content">
                        <p className="header">Grkist Office :</p>
                        <p className="part">office@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Training and Placement Cell:</p>
                        <p className="part">tpo@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Electronics & Communication Department :</p>
                        <p className="part">ec@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Computer Science & Engg. Department :</p>
                        <p className="part">cse@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Civil Engineering Department :</p>
                        <p className="part">ce@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Mechanical Engg. Department :</p>
                        <p className="part">me@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Electrical & Electronics Department :</p>
                        <p className="part">ex@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Applied Science Department :</p>
                        <p className="part">appsc@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">Pharma Department :</p>
                        <p className="part">pharmacy@grkist.com</p>
                    </div>
                    <div className="content">
                        <p className="header">MCA Department :</p>
                        <p className="part">grkistmca@gmail.com</p>
                    </div>
                </div>
                <div className="rightSection">
                    <h2 className="subHeading">How To Reach</h2>
                    <div className="mapArea">
                        <iframe 
                            className="map" 
                            title="GRKIST" 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.8350739105663!2d80.05065877515881!3d23.066507314687428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981ab2acb59131b%3A0x6d969d8625dbf2e5!2sGuru%20Ramdas%20Khalsa%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1687251088075!5m2!1sen!2sin" 
                            // width="600" 
                            // height="450"  
                            allowFullScreen="true" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade"
                            >

                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details;