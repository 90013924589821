import React from "react";
import './admissionProcess.css'

const AdmissionProcess = () => {
    return(
        <div className="admissionProcessPage">
            <h1 className="pageTitle">Admission in B.Tech</h1>
            <br />
            <hr />
            <div className="titleSection">
                <h2 className="subTitle">Admission Process</h2>
                <p className="subHeading">B.Tech COURSES</p>
            </div>
            <hr />
            <br />
            <div className="sectionContentDiv">

                <p className="sectionContent">
                The Regular admission in Engineering takes place by appearing for Joint Entrance Exam JEE Main. Further appear through MP DTE on-line counselling. Students are admitted in 1st year of BE course on the basis of their performances in Pre-Engineering Pharmacy Test (PEPT) and through Online Counseling conducted by Directorate of Technical Education, Bhopal (M.P.). If any seats remain vacant after counseling, these may be filled on the merit basis of 10+2 examination with Physics, Chemistry and Mathematics through subsequent counseling conducted by D.T.E., Bhopal.
                </p>

                <p className="sectionContent">
                <span>Lateral Entry Admissions -</span> Diploma/B.Sc. degree holders are eligible for admission to BE III semester (2nd year of the 4 year course) on the basis of their merit marks in the Diploma/B.Sc. examination. 20% of total seats in each branch are available for Diploma/ B.Sc. degree holders. These seats are filled through counseling by Directorate of Technical Education (DTE), Bhopal (M.P.)
                </p>

                <p className="contentTitle">M.TECH COURSE</p>
                <p className="sectionContent">
                Students are admitted to M.Tech course on the basis of their performance in Graduate Aptitude Test in Engineering (GATE) and through Online Counseling conducted by Directorate of Technical Education (DTE), Bhopal (M.P.). If any seats remain vacant after counseling, these may be filled on the merit basis of BE Examination through subsequent counseling conducted by Directorate of Technical Education Bhopal.
                </p>

                <p className="contentTitle">FEES STRUCTURE</p>
                <p className="sectionContent">
                The fee structure is as prescribed by Admission & Fee Regulatory Committee (AFRC), Directorate of Technical Education (DTE), M.P. and RGPV Bhopal.
                </p>

                <p className="contentTitle">DOCUMENTS REQUIRED FOR ADMISSION (ORIGINAL+2 PHOTOCOPIES)</p>
                <ul className="contentList">
                    <li className="listItem">Mark sheet of 10+2 examination</li>
                    <li className="listItem">Mark sheet of High School (10th) examination</li>
                    <li className="listItem">Transfer Certificate</li>
                    <li className="listItem">Character Certificate</li>
                    <li className="listItem">Migration Certificate (if applicable)</li>
                    <li className="listItem">Caste Certificate SC/ST/OBC</li>
                    <li className="listItem">Domicile Certificate</li>
                    <li className="listItem">Income Certificate</li>
                    <li className="listItem">Gap Certificate</li>
                    <li className="listItem">Recent Passport Size colored photographs x 10</li>
                </ul>

                <p className="contentTitle">Admission Related Query</p>
                <p className="sectionContent">
                For any help related to admission please contact : +919111104326, +918226006223 or +918226006222
                </p>
            </div>
        </div>
    )
}

export default AdmissionProcess;