import React from "react";
import './mentor.css'
import mentors from "../../../data/mentors";

const Mentor = () => {
    return(
        <div className="mentorPage">
            <h1 className="pageTitle">Our Mentors</h1>
            <hr />
            <div className="mentorTable">
                {
                    mentors.map(({id, img, post, name, desc})=>{
                        return(
                            <div key={id} className="mentorDiv">
                                <div className="leftSection">
                                    <img className="mentorImg" src={img} alt={name} />
                                </div>
                                <div className="rightSection">
                                    <h2 className="post">{post}</h2>
                                    <p className="mentorText"><span>{name} -</span> {desc}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Mentor;