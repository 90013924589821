const dropdown = [
    {
        "id":1
    },
    {
        "id":2,
        "values": [
            {
                "id": 1,
                "name": "our mentors",
                "link": "/about-us/mentors"
            },
            {
                "id": 2,
                "name": "principal message",
                "link": "/about-us/principle"
            },
            {
                "id": 3,
                "name": "mandatory disclosure",
                "link": "/about-us/mandatory-disclosure"
            }
        ]
    },
    {
        "id":3,
        "values": [
            {
                "id": 1,
                "name": "under graduate",
                "link": "/courses/undergraduate-courses"
            },
            {
                "id": 2,
                "name": "post graduate",
                "link": "/courses/postgraduate-courses"
            },
            {
                "id": 3,
                "name": "admission process",
                "link": "/courses/admissionprocess"
            }
        ]
    },
    {
        "id":4,
        "values": [
            {
                "id": 1,
                "name": "hostel",
                "link": "/facilities/hostel"
            },
            {
                "id": 2,
                "name": "sport & cultural",
                "link": "/facilities/sport&cultural"
            },            {
                "id": 3,
                "name": "labs",
                "link": "/facilities/labs"
            },
        ]
    },
    {
        "id":5,
        "values": [
            {
                "id": 1,
                "name": "tpo & placement",
                "link": "/placement/tpo&placements"
            },
            {
                "id": 2,
                "name": "accredition & tie ups",
                "link": "/placement/accredition"
            },
            {
                "id": 3,
                "name": "vt_form",
                "link": "/placement/vt_form"
            }
        ]
    },
    {
        "id":6
    },    {
        "id":7
    },
    {
        "id":8,
        "values": [
            {
                "id": 1,
                "name": "contact us",
                "link": "/contactus/connect"
            },
            {
                "id": 2,
                "name": "student redressal cell",
                "link": "/contactus/student-redressal-cell"
            },
        ]
    },
]

export default dropdown;