import React from "react";

import { labs } from "../../data/facilities";
import GalleryDesign from "../Common/GalleryDesign";

const Labs = () => {
    return(
        <div className="facilitiesBase">
            <h1 className="pageTitle">Labs & Classes</h1>
            <hr />
            <GalleryDesign data={labs} />
        </div>
    )
}

export default Labs;