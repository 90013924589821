import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// const [data, setData] = useState(null);
// useEffect(()=>{
//   boosterFundsData.filter(component => {
//     PROPOSAL_CONSTRAINTS.BOOSTER_IB_APPLICABLE_COMPO_CODES.includes(
//       component.get('compoCode')
//     ) && console.log(component.toJS())
//     setData(component.toJS());
//   })
// }, [boosterFundsData])

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
