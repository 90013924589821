import React, { useEffect, useState } from "react";
import './count.css'

import countImg from '../../Images/count.jpg'

const StudentCountSection = () => {
    const [studentCount, setStudentCount] = useState(0);
    const [facultyCount, setFacultyCount] = useState(0);
    const [staffCount, setStaffCount] = useState(0);

    useEffect(()=>{
        let studentStart=0
        let Studenttimer = setInterval(()=>{
            studentStart+=500;
            setStudentCount(studentStart+500);
            if(studentStart === 9500) clearInterval(Studenttimer);
        }, 100)

        let facultyStart=0
        let facultyTimer = setInterval(()=>{
            facultyStart+=50;
            setFacultyCount(facultyStart+50);
            if(facultyStart === 550) clearInterval(facultyTimer);
        }, 100)     

        let staffStart=0
        let staffTimer = setInterval(()=>{
            staffStart+=50;
            setStaffCount(staffStart+50);
            if(staffStart === 650) clearInterval(staffTimer);
        }, 100)
    },[])

    return(
        <div className="countSection">
            <img className="bgImg" src={countImg} alt="Our College"/>
            <div className="overlay"></div>
            <div className="contentDiv">
                <div className="count">
                    <h1 className="countTitle">{studentCount}+</h1>
                    <div className="lineDiv">
                        <div className="boxPart"></div>
                    </div>
                    <p className="subTitle">STUDENTS</p>
                </div>
                <div className="count">
                    <h1 className="countTitle">{facultyCount}+</h1>
                    <div className="lineDiv">
                        <div className="boxPart"></div>
                    </div>
                    <p className="subTitle">FACULTY</p>
                </div>
                <div className="count">
                    <h1 className="countTitle">{staffCount}+</h1>
                    <div className="lineDiv">
                        <div className="boxPart"></div>
                    </div>
                    <p className="subTitle">STAFF</p>
                </div>
            </div>
        </div>
    )
}

export default StudentCountSection;