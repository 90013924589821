import React, {useState} from "react";
import {AiOutlineSearch} from "react-icons/ai"
import './navbar.css'
import { Link,useNavigate } from "react-router-dom";

import {FiChevronRight} from 'react-icons/fi';
import {FiChevronDown} from 'react-icons/fi';


import data from '../data/dropDownLink';
// import DropDownMenu from "./DropDownMenu/DropDownMenu";

const Navbar = () =>{
    const navigate = useNavigate();

    //Web drop down state
    const [webSubNavView, setWebSubNavView] = useState("dropDownListWeb hidden");

    //states
    const [about, setAbout] = useState(false);
    const [courses, setCourses] = useState(false);
    const [facilities, setFacilities] = useState(false);
    const [placement, setPlacement] = useState(false);
    const [contact, setContact] = useState(false);

    //mobile view and basic navbar states
    const [hamburgerClicked, setHamburgerClicked] = useState(false);
    const [menuCheck, setMenuCheck] = useState("navbarDiv hidden flex"); 
    const [hamburgerState, setHamburgerState] = useState("stick")

    const menuClick = () =>{
        if(!hamburgerClicked){
            setHamburgerClicked(!hamburgerClicked);
            setMenuCheck("navbarDiv flex");
            setHamburgerState("stick clicked");
        }
        else {
            setHamburgerClicked(!hamburgerClicked);
            setMenuCheck("navbarDiv hidden flex");
            setHamburgerState("stick unclicked");
        }
    }


    //Dropdown menu section
    const [dropdown, setDropdown] = useState("subNavbarDiv hidden");
    const [dropdownList, setdropdownList] = useState()
    const menuBtnClicked = (id) => {

        if(id === 1 || id === 6 || id === 7){
            if(id===1) navigate("/");
            if(id===6) navigate("/gallery");
            if(id===7) navigate("/faq");

            setHamburgerClicked(!hamburgerClicked);
            setMenuCheck("navbarDiv hidden flex");
            setHamburgerState("stick unclicked");
            setDropdown("subNavbarDiv hidden");
            setWebSubNavView("dropDownListWeb hidden");
            setAbout(false);
            setCourses(false);
            setFacilities(false);
            setPlacement(false);
            setContact(false);

        } else {
            setDropdown("subNavbarDiv");
            setMenuCheck("navbarDiv extend flex");
            setdropdownList(data[id-1].values);
            setWebSubNavView("dropDownListWeb");

            if(id===2){
                setAbout(true);
                setCourses(false);
                setFacilities(false);
                setPlacement(false);
                setContact(false);
            } else  if(id===3){
                setAbout(false);
                setCourses(true);
                setFacilities(false);
                setPlacement(false);
                setContact(false);
            } else  if(id===4){
                setAbout(false);
                setCourses(false);
                setFacilities(true);
                setPlacement(false);
                setContact(false);
            } else  if(id===5){
                setAbout(false);
                setCourses(false);
                setFacilities(false);
                setPlacement(true);
                setContact(false);
            } else  if(id===8){
                setAbout(false);
                setCourses(false);
                setFacilities(false);
                setPlacement(false);
                setContact(true);
            }
        }

    }


    //for mobile and tablet web view
    const handleSubNav = () => {
        setDropdown("subNavbarDiv hidden");
        setMenuCheck("navbarDiv back flex");
    }
    const menuLink = (link) => {
        setHamburgerClicked(false);
        setDropdown("subNavbarDiv hidden");
        setMenuCheck("navbarDiv hidden flex");
        setHamburgerState("stick unclicked");
        navigate(link);
    }



    //handling states for desktop web view subnav
    const handleWebSubNavClick = () => {
        setTimeout(()=>{
            setAbout(false);
            setCourses(false);
            setFacilities(false);
            setPlacement(false);
            setContact(false);
            setWebSubNavView("dropDownListWeb hidden");
        }, 50)



    }

    //Login Page Redirect
    const loginPageHandle = () => {
        navigate("/login");
    }


    //Navbar style
    const [navbarStyle,setNavbarStyle] = useState("flex topbarBox");
    document.addEventListener('scroll', () => {

        if(window.scrollY > 0) {
            setNavbarStyle("flex topbarBox scrolled")
        } else {
            setNavbarStyle("flex topbarBox")
        }
    })

    return(
        <div className={navbarStyle}>
            <div className="left-section">
                <div className="searchBox">
                    <AiOutlineSearch className="icon" />
                </div>
            </div>

            <div className="right-section flex">
                <div className={menuCheck}>
                    <ul className="navList flex">
                        <li className="navItem trigger" onClick={()=>menuBtnClicked(1)}>HOME</li>
                        <li className="navItem" onClick={()=>menuBtnClicked(2)}>ABOUT US<FiChevronDown className="menuIcon"/>
                        {
                            about ? <>
                                <ul className={webSubNavView}>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/about-us/mentors'}>Our Mentors</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/about-us/principle'}>Principal Message</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/about-us/mandatory-disclosure'}>Mandatory Disclosure</Link>
                                        </li>
                                    </div>
                                </ul>
                            </> : <></>
                        }
                        </li>
                        <li className="navItem" onClick={()=>menuBtnClicked(3)}>COURSES<FiChevronDown className="menuIcon"/>
                        {
                            courses ? <>
                                <ul className={webSubNavView}>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem" >
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/courses/undergraduate-courses'}>undergraduate courses</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/courses/postgraduate-courses'}>postgraduate courses</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/courses/admissionprocess'}>admission process</Link>
                                        </li>
                                    </div>
                                </ul>
                            </> : <></>
                        }

                        </li>
                        <li className="navItem" onClick={()=>menuBtnClicked(4)}>FACILITIES<FiChevronDown className="menuIcon"/>
                        {
                            facilities ? <>
                                <ul className={webSubNavView}>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/facilities/hostel'}>hostel</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv"  onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/facilities/sport&cultural'}>sport & cultural</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv" onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/facilities/labs'}>labs</Link>
                                        </li>
                                    </div>
                                </ul>
                            </> : <></>
                        }

                        </li>
                        <li className="navItem" onClick={()=>menuBtnClicked(5)}>PLACEMENT<FiChevronDown className="menuIcon"/>
                        {
                            placement ? <>
                                <ul className={webSubNavView}>
                                    <div className="listItemDiv" onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/placement/tpo&placements'}>tpo & placements</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv" onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/placement/accredition'}>accredition & tie-ups</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv" onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/placement/vt_form'}>vt_form</Link>
                                        </li>
                                    </div>
                                </ul>
                            </> : <></>
                        }

                        </li>
                        <li className="navItem" onClick={()=>menuBtnClicked(6)}>GALLERY</li>
                        <li className="navItem" onClick={()=>menuBtnClicked(7)}>FAQs</li>
                        <li className="navItem" onClick={()=>menuBtnClicked(8)}>CONTACT<FiChevronDown className="menuIcon"/>
                        {
                            contact ? <>
                                <ul className={webSubNavView}>
                                    <div className="listItemDiv" onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/contactus/connect'}>contact us</Link>
                                        </li>
                                    </div>
                                    <div className="listItemDiv" onClick={handleWebSubNavClick}>
                                        <li className="navItem">
                                            <FiChevronRight className="icon"/>
                                            <Link className="link color" to={'/contactus/student-redressal-cell'}>student redressal cell</Link>
                                        </li>
                                    </div>
                                </ul>
                            </> : <></>
                        }

                        </li>
                    </ul>
                    <div className="loginBtn btn" onClick={loginPageHandle}>
                        <h4 className="loginText">LOGIN</h4>
                    </div>
                </div>
                <div className="hamburger-icon" onClick={menuClick}>
                    <div className={hamburgerState}></div>
                    <div className={hamburgerState}></div>
                    <div className={hamburgerState}></div>
                </div>

                {
                    //Drop down menu
                    dropdown ?
                    <>
                        <div className={dropdown}>
                            <div className="subHamburger" onClick={handleSubNav}>
                                <div className="stick"></div>
                                <div className="stick"></div>
                                <div className="stick"></div>
                            </div>
                            <ul className="navList flex">
                                {
                                    dropdownList ?
                                    dropdownList.map(({id, name, link})=>{
                                        return(
                                            <div key={id}>
                                                <li className="navItem trigger" onClick={()=>menuLink(link)}>{name}</li>
                                            </div>
                                        )
                                    }) : <></>
                                }
                            </ul>
                        </div>
                    </> : <></>
                }
            </div>
        </div>
    );
}



export default Navbar;