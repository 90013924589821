import React, { useState } from "react";
import './gallery.css'

import GalleryDesign from "../Common/GalleryDesign";
import { all, activities, academics } from "../../data/galleryData";

const Gallery = () => {
    const [allState, setAllState] = useState(true);
    const [campusState, setCampusState] = useState(false);
    const [activitiesState, setActivitiesState] = useState(false);
    const [facilities, setFacilities] = useState(false);

    const handleClick = (id) => {
        if(id === 1){
            setAllState(true);
            setCampusState(false);
            setActivitiesState(false);
            setFacilities(false);
        } else if(id === 2){
            setAllState(false);
            setCampusState(true);
            setActivitiesState(false);
            setFacilities(false);
        } else if(id === 3){
            setAllState(false);
            setCampusState(false);
            setActivitiesState(true);
            setFacilities(false);
        }   else {
            setAllState(false);
            setCampusState(false);
            setActivitiesState(false);
            setFacilities(true);
        }
    }

    return(
        <div className="galleryDiv">
            <h1 className="pageTitle">Gallery</h1>
            <ul className="galleryList">
                <li className="listItem" onClick={()=>{handleClick(1)}}>
                    <div className="listItemDiv">
                        <p className="itemTitle">All</p>
                    </div>
                </li>
                <li className="listItem" onClick={()=>{handleClick(2)}}>
                    <div className="listItemDiv">
                        <p className="itemTitle">Campus</p>
                    </div>
                </li>
                <li className="listItem" onClick={()=>{handleClick(3)}}>
                    <div className="listItemDiv">
                        <p className="itemTitle">Student Activities</p>
                    </div>
                </li>
                <li className="listItem" onClick={()=>{handleClick(4)}}>
                    <div className="listItemDiv">
                        <p className="itemTitle">Facilities</p>
                    </div>
                </li>
            </ul>

            {
                allState ? <GalleryDesign data={all} /> : <></>
            }

            {
                campusState ? <GalleryDesign data={activities}/> : <></>
            }

            {
                activitiesState ? <GalleryDesign data={activities}/> : <></>
            }  

            {
                facilities ? <GalleryDesign data={academics}/> : <></>
            }     
        </div>
    )
}

export default Gallery;