import './App.css';
// import Topline from './Components/TopLine/Topline';
import Carousel from './Components/Carousel/Carousel.jsx';
import Navbar from './Components/Navbar/Navbar';
import Headers from './Components/Headers/Headers';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';
import SocialIconBar from './Components/socialIconBar/SocialIconBar';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Courses from './Components/Menu Pages/Courses/CoursesSection'
import AdmissionProcess from './Components/Menu Pages/AdmissionProcess/AdmissionProcess';
import Hostel from './Components/Menu Pages/Facilities/Hostel';
import SportAndCultural from './Components/Menu Pages/Facilities/Sport&Cultural';
import Gallery from './Components/Menu Pages/Gallery/Gallery';
import Accredition from './Components/Menu Pages/Placement/Accredition/Accredition';
import TPO from './Components/Menu Pages/Placement/TPO/TPO';
import Details from './Components/Menu Pages/Contact/Details/Details';
import GrievanceSection from './Components/Menu Pages/Contact/GrievanceSection/GrievanceSection';
import Login from './Components/Menu Pages/Login/Login';
import Registration from './Components/Menu Pages/RegistrationPage/RegistrationPage';
import FAQ from './Components/Menu Pages/FAQ/FAQ';
import Mentor from './Components/Menu Pages/About Us/MentorsPage/Mentor';
import Principle from './Components/Menu Pages/About Us/PrinciplePage/Principle';
import PrincipleHomeSection from './Components/PrincipalHomeSection/PrincipleHome';
import StudentCountSection from './Components/StudentCountSection/StudentCountSection';
import MainFooter from './Components/Main Footer/MainFooter';
import ScrollToTop from './Components/ScrollToTop/scrollToTop';
import Disclosure from './Components/Menu Pages/About Us/MandatoryDisclosure/Disclosure';
import VTForm from './Components/Menu Pages/Placement/VT_Form/VT_Form';
import Labs from './Components/Menu Pages/Facilities/Labs';
import PostGraduate from './Components/Menu Pages/Courses/PostGraduate';

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element:
      <>
        <ScrollToTop />
        <Carousel/>
        <Navbar />
        <Main />
        <PrincipleHomeSection />
        <StudentCountSection />
        <Headers />
        <MainFooter />
        <Footer />
      </>
    },
    {
      path: '/about-us/mentors',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Mentor />
        <Footer />
      </>
    },   
    {
      path: '/about-us/principle',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Principle />
        <Footer />
      </>
    },
    {
      path: '/about-us/mandatory-disclosure',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Disclosure />
        <Footer />
      </>
    },  
    {
      path: '/courses/undergraduate-courses',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Courses />
        <Footer />
      </>
    },
    {
      path: '/courses/postgraduate-courses',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <PostGraduate />
        <Footer />
      </>
    },
    {
      path: '/courses/admissionprocess',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <AdmissionProcess />
        <Footer />
      </>
    },
    {
      path: '/facilities/hostel',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Hostel />
        <Footer />
      </>
    },
    {
      path: '/facilities/sport&cultural',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <SportAndCultural />
        <Footer />
      </>
    },
    {
      path: '/facilities/labs',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Labs />
        <Footer />
      </>
    },
    {
      path: '/gallery',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Gallery />
        <Footer />
      </>
    },
    {
      path: '/placement/accredition',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Accredition />
        <Footer />
      </>
    },
    {
      path: '/placement/tpo&placements',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <TPO />
        <Footer />
      </>
    },
    {
      path: '/placement/vt_form',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <VTForm />
        <Footer />
      </>
    },
    {
      path: '/contactus/connect',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <Details />
        <Footer />
      </>
    },
    {
      path: '/contactus/student-redressal-cell',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <GrievanceSection />
        <Footer />
      </>
    },
    {
      path: '/login',
      element:
      <>
        <Navbar />
        <Login />
      </>
    },
    {
      path: '/registration',
      element:
      <>
        <Navbar />
        <Registration />
      </>
    },
    {
      path: '/faq',
      element:
      <>
        <ScrollToTop />
        <Navbar />
        <FAQ />
        <Footer />
      </>
    },
  ])
  return (
    <div className="App">
      <SocialIconBar />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
